<template>
  <div class="container p-0">
    <div class="selectSession">
      <label class="font-weight-bold pt-2 pl-0 col-12">Select session</label>
      <alert v-if="loading" class="py-2 light-shadow col-12"></alert>
      <select
        v-model="selectedSession"
        v-if="!loading"
        class="form-control light-shadow"
      >
        <option value="-1" selected disabled>Select session</option>
        <option
          v-for="sessionOption in getSessionOptions"
          :key="sessionOption.value"
          :value="sessionOption.value"
        >
          {{ sessionOption.label }}
        </option>
      </select>
    </div>
    <alert
      v-if="currentQuestionnaireLoading"
      class="py-8-px light-shadow col-12"
    ></alert>
    <div
      class="custom-control custom-checkbox my-3"
      v-if="
        selectedSession != -1 && !currentQuestionnaireLoading && !isFilled()
      "
    >
      <input
        type="checkbox"
        class="custom-control-input"
        id="no_pain_reported"
        v-model="noPainReported"
        @click="toggleNoPainReported"
      />
      <label class="custom-control-label mr-3 mb-3" for="no_pain_reported"
        >No Pain Reported</label
      >
    </div>
    <form
      @submit.prevent="sendData"
      v-if="selectedSession != -1 && !currentQuestionnaireLoading"
      class="card theme-card p-3 p-md-4 p-lg-4 col-12"
    >
      <LocationOfPain v-if="activeStep === 1 && !noPainReported" />
      <RestOfSteps v-if="activeStep === 2" :noPainReported="noPainReported" />
      <GeneralSymptomsObserved v-if="activeStep === 3" />
      <div class="buttons mt-3">
        <button
          v-if="activeStep == 3 || (activeStep == 2 && !noPainReported)"
          type="button"
          class="btn btn-secondary"
          @click="handleStep(-1)"
        >
          Back
        </button>
        <div>
          <button
            v-if="activeStep == 1 || activeStep == 2"
            type="button"
            class="btn btn-theme px-5"
            @click="handleStep(1)"
          >
            Next
          </button>
          <save
            v-if="activeStep == 3"
            classes="save-button-60 btn btn-theme"
            :saving="saving"
            >Save Questionnaire</save
          >
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import dayjs from "dayjs";
import LocationOfPain from "./steps/LocationOfPain.vue";
import RestOfSteps from "./steps/RestOfSteps.vue";
import Swal from "sweetalert2";
import GeneralSymptomsObserved from "./steps/GeneralSymptomsObserved.vue";

export default {
  components: { LocationOfPain, RestOfSteps, GeneralSymptomsObserved },
  data() {
    return {
      selectedSession: -1,
      activeStep: 1,
      latestQuestionnaire: {},
      noPainReported: false,
    };
  },
  mounted() {
    this.setCurrentQuestionnaire({});
    this.getQuestionnaires(this.currentPatient.user_id);
    this.getActivityCategories();
  },
  watch: {
    selectedSession() {
      const vm = this;
      this.setStep(1);
      this.noPainReported = false;
      this.getQuestionnaire(this.selectedSession).then(() => {
        vm.latestQuestionnaire = JSON.parse(
          JSON.stringify(vm.currentQuestionnaire)
        );
        if (
          vm.currentQuestionnaire.body_locations &&
          !vm.currentQuestionnaire.body_locations.length &&
          this.isFilled()
        ) {
          this.noPainReported = true;
          this.setStep(2);
        }
      });
    },
  },
  created() {
    window.addEventListener("beforeunload", this.whenRefresh);
  },

  beforeDestroy() {
    window.removeEventListener("beforeunload", this.whenRefresh);
  },
  computed: {
    ...mapState({
      currentPatient: (state) => state.patients.patient,
      questionnaires: (state) => state.patients.symptoms.questionnaire.data,
      loading: (state) => state.patients.symptoms.questionnaire.loading,
      currentQuestionnaireLoading: (state) =>
        state.patients.symptoms.currentQuestionnaire.loading,
      currentQuestionnaire: (state) =>
        state.patients.symptoms.currentQuestionnaire.data,
      saving: (state) => state.patients.symptoms.saving,
    }),
    getSessionOptions: function () {
      const newQuestionnaire = [...this.questionnaires];
      const sortedQuestionnaires = newQuestionnaire.sort((apt1, apt2) => {
        return dayjs(apt2.apt_start_time).subtract(dayjs(apt1.apt_start_time));
      });
      return sortedQuestionnaires.map((apt) => {
        const option = {
          value: apt.id,
        };
        let label = dayjs(apt.apt_start_time)
          .utc()
          .format("MMM D, YYYY hh:mm A");
        label =
          label +
          " - " +
          dayjs(apt.apt_end_time).utc().format("MMM D, YYYY hh:mm A");
        if (apt.apt_service_name) {
          label = label + " (" + apt.apt_service_name + ")";
        }
        option.label = label;
        return option;
      });
    },
  },
  methods: {
    ...mapActions({
      getQuestionnaires: "patients/symptoms/getAllQuestionnaires",
      getQuestionnaire: "patients/symptoms/getQuestionnaire",
      saveQuestionnaire: "patients/symptoms/saveQuestionnaire",
      setCurrentQuestionnaire: "patients/symptoms/setCurrentQuestionnaire",
      getActivityCategories: "patients/symptoms/getActivityCategories",
    }),
    handleStep: function (step) {
      this.activeStep += step;
    },
    setStep: function (value) {
      this.activeStep = value;
    },
    sendData: function () {
      const vm = this;
      if (
        this.currentQuestionnaire.body_locations &&
        !this.currentQuestionnaire.body_locations.length &&
        !this.noPainReported
      ) {
        Swal.fire({
          title: "Error",
          html: `Please select one or more location of pain`,
          icon: "error",
        });
        this.setStep(1);
        return;
      }
      if (
        this.currentQuestionnaire.activity_categories &&
        !this.currentQuestionnaire.activity_categories.length
      ) {
        Swal.fire({
          title: "Error",
          html: `Please select one or more activity category`,
          icon: "error",
        });
        return;
      }
      const copy = JSON.parse(JSON.stringify(this.currentQuestionnaire));
      const questionKeys = [
        "pain_rate_client",
        "pain_rate_provider",
        "fatigue_rate_client",
        "fatigue_rate_provider",
        "mood_rate_client",
        "mood_rate_provider",
        "headaches_rate_client",
        "headaches_rate_provider",
      ];
      questionKeys.forEach((item) => {
        if (!copy[item]) {
          copy[item] = "0";
        }
      });
      if (this.noPainReported) {
        copy.location_of_pain = [];
        copy.time_pain_client = null;
        copy.time_pain_provider = null;
        copy.pain_rate_client = null;
        copy.pain_rate_provider = null;
      } else {
        copy.location_of_pain = copy.body_locations;
      }
      this.setCurrentQuestionnaire(copy);
      if (!this.validateQuestionnaire(copy)) {
        return;
      }
      if (this.isFilled()) {
        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: "btn btn-black ml-4",
            cancelButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });

        swalWithBootstrapButtons
          .fire({
            title: "Are you sure?",
            text: "You are going to modify this questionnaire",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, modify!",
            cancelButtonText: "No, cancel!",
            reverseButtons: true,
          })
          .then((result) => {
            if (result.isConfirmed) {
              vm.confirmSaveQuestionnaire();
            }
          });
      } else {
        this.confirmSaveQuestionnaire();
      }
    },
    confirmSaveQuestionnaire: function () {
      this.saveQuestionnaire({
        id: this.currentQuestionnaire.id,
        data: this.currentQuestionnaire,
      }).then((res) => {
        if (res) {
          this.latestQuestionnaire = JSON.parse(
            JSON.stringify(this.currentQuestionnaire)
          );
          window.removeEventListener("beforeunload", this.whenRefresh);
        }
      });
    },
    whenRefresh: function (e) {
      if (
        // eslint-disable-next-line no-undef
        _.isEqual(this.currentQuestionnaire, this.latestQuestionnaire) === false
      ) {
        e.preventDefault();
        e.returnValue = "";
      }
    },
    toggleNoPainReported: function () {
      if (!this.noPainReported && this.activeStep === 1) {
        this.setStep(2);
      }
      if (this.noPainReported && this.activeStep !== 1) {
        this.setStep(1);
      }
    },
    isFilled: function () {
      return this.latestQuestionnaire.hours_client !== null;
    },
    validateQuestionnaire: function (sentQuestionnaire) {
      const questionnaireFieldsMapping = [
        {
          fieldName: "hours_client",
          step: 2,
          message: "The number of sleeping hours field is required",
        },
        {
          fieldName: "hours_provider",
          step: 2,
          message: "The number of sleeping hours field is required",
        },
        {
          fieldName: "anxiety_client",
          step: 2,
          message: "The anxiety reported field is required",
        },
        {
          fieldName: "anxiety_provider",
          step: 2,
          message: "The anxiety reported field is required",
        },
      ];
      if (!this.noPainReported) {
        questionnaireFieldsMapping.unshift(
          ...[
            {
              fieldName: "time_pain_client",
              step: 2,
              message: "The time of pain field is required",
            },
            {
              fieldName: "time_pain_provider",
              step: 2,
              message: "The time of pain field is required",
            },
          ]
        );
      }
      for (let i = 0; i < questionnaireFieldsMapping.length; i++) {
        const item = questionnaireFieldsMapping[i];
        if (!sentQuestionnaire[item.fieldName]) {
          this.fireError(item.message);
          this.setStep(item.step);
          return false;
        }
      }
      return true;
    },
    fireError: function (message) {
      Swal.fire({
        title: "Error",
        html: message,
        icon: "error",
      });
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  min-height: 60vh;
}
.selectSession {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  .form-control {
    width: 100%;
  }
}

.buttons {
  width: 100%;
  &:last-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }
}
</style>
