<template>
  <div class="overflow-auto general-symptoms-observed">
    <table class="table table-sm table-stripe mb-0 table-custom border">
      <thead>
        <tr>
          <th class="text-center" colspan="9">General Symptoms Observed</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(question, index) in GSOQuestions" :key="index">
          <td>
            {{ question.text }}

            <span class="text-muted small" v-if="question.note">
              <br />({{ question.note }})</span
            >
          </td>
          <td>
            <div>
              <select
                required
                class="form-control"
                v-model="dataToSend[question.key]"
              >
                <option
                  v-for="(option, index) in options"
                  :value="option.value"
                  :key="index"
                >
                  {{ option.label }}
                </option>
              </select>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      options: [
        { label: "No", value: 0 },
        { label: "Yes", value: 1 },
      ],
      dataToSend: {},
    };
  },
  mounted() {
    this.dataToSend = JSON.parse(JSON.stringify(this.questionnaire));
  },
  computed: {
    ...mapState({
      questionnaire: (state) =>
        state.patients.symptoms.currentQuestionnaire.data,
      GSOQuestions: (state) => state.patients.symptoms.GSOQuestions,
    }),
  },
  watch: {
    dataToSend: {
      handler(newValue) {
        this.setCurrentQuestionnaire(newValue);
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      setCurrentQuestionnaire: "patients/symptoms/setCurrentQuestionnaire",
    }),
  },
};
</script>

<style lang="scss" scoped>
.general-symptoms-observed {
  max-height: 640px !important;
  table {
    tbody {
      tr {
        td:first-child {
          width: 50%;
          min-width: 300px;
        }
        td:last-child {
          min-width: 200px;
        }
      }
    }
  }
}
</style>
